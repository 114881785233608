<!-- App.vue -->

<template class="dark">
  <div>
    <div id="app">
      <!-- Barra de navegación u otros elementos globales -->
      <router-view :user="user" :onActualizarUser="actualizarUser" :sidenav="activeSide" :onCambiarSidenav="actualizarSide" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: '',
      activeSide: true,
      width: window.innerWidth,
    };
  },

  mounted() {
    console.log(this.$route.path);
    this.user = JSON.parse(localStorage.getItem('usuario'));
    if(this.$route.path != '/login' && (this.user == '' || this.user == null)) {
      this.$router.push('/login');
    }
    if(this.$route.path == '/login' && (this.user != '' && this.user != null)) {
      this.$router.push('/');
    }
    window.addEventListener('resize', this.onResize);
  },
  // En el componente padre
  emits: [
    'actualizarCesta',
    'actualizarDatos'
  ],

  watch: {
    // Observa los cambios en la ruta actual y actualiza el título de la página
    '$route'({ meta }) {
      document.title = meta.title || 'Horno Goimar'; // Título predeterminado si la ruta no tiene título
      if(this.width < 1025) {
        this.activeSide = false;
      }
    }
  },
  methods: {
    onResize() {
        this.width = window.innerWidth
        if(this.width < 1025) {
        this.activeSide = false;
      } else {
        this.activeSide = true;
      }
    },
    actualizarUser(nuevo) {
      this.user = nuevo;
    },
    actualizarSide() {
      this.activeSide = !this.activeSide;
    },
  }
}
</script>

<style lang="scss">
</style>