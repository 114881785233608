<template>
    <body>
        <Header :user="user" :onCambiarSidenav="cambiarSidenav" />
        <!-- Top Bar End -->
        <div class="page-wrapper">
            <div class="page-wrapper-inner">
                <!-- Left Sidenav -->
                <Sidenav :sidenav="sidenav" />
                <!-- end left-sidenav-->
                <!-- Page Content-->
                <div class="page-content">
                    <div class="container-fluid">
                        <div class="card">
                            <div class="card-body">
                                <h4>Crear nuevo cliente</h4>
                                <div class="row" v-loading="loading">
                                    <div class="col-md-12 mb-3">
                                        <p class="mb-0"><b>Nombre</b></p>
                                        <el-input v-model="nuevoCliente.nombre" placeholder="Nombre" />
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <p class="mb-0"><b>Apellido</b></p>
                                        <el-input v-model="nuevoCliente.apellido" placeholder="Apellido" />
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <p class="mb-0"><b>Teléfono</b></p>
                                        <el-input v-model="nuevoCliente.telefono" placeholder="Teléfono" />
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <button @click="crearCliente" type="button"
                                            class="btn btn-block btn-success waves-effect waves-light">Crear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!--end row-->
                </div><!-- container -->

                <footer class="footer text-center text-sm-left">
                    &copy; 2019 Frogetor <span class="text-muted d-none d-sm-inline-block float-right">Crafted with <i
                            class="mdi mdi-heart text-danger"></i> by Mannatthemes</span>
                </footer>
            </div>
            <!-- end page content -->
        </div>
        <!-- end page-wrapper -->
    </body>
</template>
  
<script>
import Header from './Header.vue'
import Sidenav from './Sidenav.vue'
export default {
    name: 'NuevoCliente',
    props: {
        user: Object,
        sidenav: Boolean
    },
    components: {
        Header,
        Sidenav
    },
    computed: {
        filteredClientes() {
            let clientes = [].concat(this.clientes);
            let busqueda = this.busqueda.toLowerCase();
            if (busqueda) {
                clientes = clientes.filter((cliente) => { return cliente.name.toLowerCase().includes(busqueda) || cliente.telefono.includes(busqueda) })
            }
            return clientes;
        }
    },
    data() {
        return {
            usuario: '',
            loading: false,
            nuevoCliente: {
                nombre: '',
                apellido: '',
                telefono: ''
            },
            clientes: [],
        }
    },
    mounted() {
        this.usuario = this.user
    },

    methods: {
        cambiarSidenav() {
            this.$emit('cambiarSidenav');
        },
        async crearCliente() {
            this.loading = true;
            const url = process.env.VUE_APP_BACKEND_URL + '/api/nuevoCliente';

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token, cliente: this.nuevoCliente })
                });
                this.nuevoCliente = {
                    nombre: '',
                    apellido: '',
                    telefono: ''
                }
                this.clientes = await response.json();
                this.loading = false;
                this.$router.push('/cliente/' + this.clientes[0].id);
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#contenedorInicio {
    display: flex;
    justify-content: center;
    align-items: center;

    .texto {
        text-align: center;
        color: white;
        max-width: 75%;

        img {
            width: 30%;
        }

        h1 {
            margin-bottom: 5px;
        }

        h3 {
            margin-top: 0px;
            font-weight: normal;
        }

        button {
            font-size: 1.5rem;
            padding: 20px;

            i {
                margin-right: 10px;
            }
        }
    }
}
</style>
  