<template>
    <body>
        <Header :user="user" :onCambiarSidenav="cambiarSidenav"/>
        <!-- Top Bar End -->
        <div class="page-wrapper">
            <div class="page-wrapper-inner">
                <!-- Left Sidenav -->
                <Sidenav :sidenav="sidenav"/>
                <!-- end left-sidenav-->
                <!-- Page Content-->
                <div class="page-content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <h4 class="mt-0">¡Hola, {{ user.user }}!</h4>
                                                <p class="text-muted">Aquí tienes un resumen rápido</p>
                                                <div class="row justify-content-center" v-loading="loading">
                                                    <div class="col-md-3">
                                                        <div class="card mb-0">
                                                            <div class="card-body">
                                                                <div class="float-right">
                                                                    <i class="fa-light fa-donut font-20 text-secondary"></i>
                                                                </div>
                                                                <span class="badge badge-danger">Roscos pendientes</span>
                                                                <h3 class="font-weight-bold">
                                                                {{ estadisticas.roscospendientes }}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card mb-0">
                                                            <div class="card-body">
                                                                <div class="float-right">
                                                                    <i class="fa-solid fa-donut font-20 text-secondary"></i>
                                                                </div>
                                                                <span class="badge badge-info">Roscos entregados</span>
                                                                <h3 class="font-weight-bold">
                                                                   {{estadisticas.roscosentregados}}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card mb-0">
                                                            <div class="card-body">
                                                                <div class="float-right">
                                                                    <i class="fa-light fa-store font-20 text-secondary"></i>
                                                                </div>
                                                                <span class="badge badge-warning">Pedidos pendientes</span>
                                                                <h3 class="font-weight-bold">
                                                                {{ estadisticas.pedidospendientes }}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card mb-0">
                                                            <div class="card-body">
                                                                <div class="float-right">
                                                                    <i class="fa-solid fa-store font-20 text-secondary"></i>
                                                                </div>
                                                                <span class="badge badge-success">Pedidos entregados</span>
                                                                <h3 class="font-weight-bold">
                                                                {{ estadisticas.pedidosentregados }}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card mt-3">
                                                            <div class="card-body">
                                                                <div class="float-right">
                                                                    <i
                                                                        class="fa-solid fa-cash-register font-20 text-secondary"></i>
                                                                </div>
                                                                <span class="badge badge-primary">Total recaudado
                                                                    roscos</span>
                                                                <h3 class="font-weight-bold">
                                                                {{ estadisticas.totalrecaudado }}€
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card mt-3">
                                                            <div class="card-body">
                                                                <div class="float-right">
                                                                    <i
                                                                        class="fa-solid fa-person font-20 text-secondary"></i>
                                                                </div>
                                                                <span class="badge badge-success">Clientes</span>
                                                                <h3 class="font-weight-bold">
                                                                   {{estadisticas.numclientes}}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card mt-3">
                                                            <div class="card-body">
                                                                <div class="float-right">
                                                                    <i
                                                                        class="fa-regular fa-sack-dollar font-20 text-secondary"></i>
                                                                </div>
                                                                <span class="badge badge-danger">Promedio de venta por
                                                                    cliente</span>
                                                                <h3 class="font-weight-bold">
                                                                   {{estadisticas.promedioventa}}€
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card mt-3">
                                                            <div class="card-body">
                                                                <div class="float-right">                  
                               <i v-if="estadisticas.estadopedidos" class="fa-solid fa-power-off font-20 text-success"></i>
                                <i v-if="!estadisticas.estadopedidos" class="fa-solid fa-power-off font-20 text-danger"></i>
                                                                </div>
                                                                <span class="badge badge-info">Estado de los pedidos</span>
                                                                <h3 class="font-weight-bold">
                                                                {{ estadisticas.estadopedidos ? 'ON' : 'OFF' }}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 align-self-center">
                                                <img src="assets/images/dash.svg" alt class="img-fluid">
                                            </div>
                                        </div>
                                    </div>
                                    <!--end card-body-->
                                </div>
                                <!--end card-->
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!-- container -->
                    <footer class="footer text-center text-sm-left">
                        &copy; 2019 Frogetor <span class="text-muted d-none d-sm-inline-block float-right">Crafted
                            with <i class="mdi mdi-heart text-danger"></i> by
                            Mannatthemes</span>
                    </footer>
                </div>
                <!-- end page content -->
            </div>
            <!--end page-wrapper-inner -->
        </div>
        <!-- end page-wrapper -->
    </body>
</template>
  
<script>
import Header from './Header.vue'
import Sidenav from './Sidenav.vue'
export default {
    name: 'PaginaInicio',
    props: {
        user: Object,
        sidenav: Boolean,
    },
    components: {
        Header,
        Sidenav
    },
    data() {
        return {
            configuracion: '',
            usuario: '',
            loading: true,
            estadisticas: { "roscospendientes": 0, "roscosentregados": 0, "pedidospendientes": 0, "pedidosentregados": 0, "totalrecaudado": 0, "numclientes": 0, "promedioventa": 0, "estadopedidos": 1 },
        }
    },
    mounted() {
        this.init();
        this.usuario = this.user
    },

    methods: {
        cambiarSidenav() {
           this.$emit('cambiarSidenav');
        },
        async init() {
            const url = process.env.VUE_APP_BACKEND_URL + '/api/obtenerEstadisticas';

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token })
                });
                this.estadisticas = await response.json();
                this.loading = false;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#contenedorInicio {
    display: flex;
    justify-content: center;
    align-items: center;

    .texto {
        text-align: center;
        color: white;
        max-width: 75%;

        img {
            width: 30%;
        }

        h1 {
            margin-bottom: 5px;
        }

        h3 {
            margin-top: 0px;
            font-weight: normal;
        }

        button {
            font-size: 1.5rem;
            padding: 20px;

            i {
                margin-right: 10px;
            }
        }
    }
}</style>
  