<template>
    <body>
        <Header :user="user" :onCambiarSidenav="cambiarSidenav" />
        <!-- Top Bar End -->
        <div class="page-wrapper">
            <div class="page-wrapper-inner">
                <!-- Left Sidenav -->
                <Sidenav :sidenav="sidenav"/>
                <!-- end left-sidenav-->
                <!-- Page Content-->
                <div class="page-content" v-loading="loading">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body border-bottom">
                                        <div class="fro_profile">
                                            <div class="row">
                                                <div class="col-lg-12 mb-3 mb-lg-0">
                                                    <div class="fro_profile-main">
                                                        <div class="fro_profile-main-pic">
                                                            <img src="https://i.imgur.com/8n5W7Px.png" width="60px" alt=""
                                                                class="rounded-circle">
                                                        </div>
                                                        <div class="fro_profile_user-detail">
                                                            <h5 class="fro_user-name">{{ cliente.name }}</h5>
                                                            <a :href="`tel:+34${cliente.telefono}`">
                                                                <p class="mb-0 fro_user-name-post"><i
                                                                        class="fa-duotone fa-phone"></i> {{ cliente.telefono
                                                                        }}</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div><!--end col-->
                                            </div><!--end row-->
                                        </div><!--end f_profile-->
                                    </div><!--end card-body-->
                                </div><!--end card-->
                            </div><!--end col-->
                        </div><!--end row-->
                        <div class="row" v-if="idVer != 0">
                            <div class="col-lg-12" v-if="!modificar">
                                <div class="card">
                                    <div class="card-body">
                                        <h2><button @click="idVer = 0" type="button"
                                                class="btn btn-warning waves-effect waves-light float-left me-3"><i
                                                    class="fa-solid fa-left"></i></button> Ver pedido <button
                                                @click="modificar = true" v-if="!pedidoFiltrado.entregado" type="button"
                                                class="btn btn-warning waves-effect waves-light float-right"><i
                                                    class="fa-solid fa-pen-to-square"></i> Modificar pedido</button></h2>
                                        <div style="cursor:pointer;" class="card shadow-none my-1 overflow-hidden">
                                            <div
                                                :class="`card-body ${pedidoFiltrado.entregado ? 'bg-gradient1' : 'bg-gradient3'}`">
                                                <div class="">
                                                    <div class="card-icon">
                                                        <i :class="`fa-duotone ${pedidoFiltrado.entregado ? 'fa-check' : 'fa-spinner'}`"></i>
                                                    </div>
                                                    <h2 class="font-weight-bold text-white">{{
                                                        pedidoFiltrado.fechaFormateada
                                                    }}</h2>
                                                    <p v-if="!pedidoFiltrado.pagado" class="text-white mb-0 font-16"><span
                                                            class="badge badge-warning float-left">Pedido no
                                                            pagado</span></p>
                                                    <p v-if="pedidoFiltrado.pagado" class="text-white mb-0 font-16">
                                                        <span class="badge badge-success float-left">Pedido
                                                            pagado</span>
                                                    </p>
                                                    <p v-if="!pedidoFiltrado.entregado" class="text-white mb-0 font-16">
                                                        <span class="badge badge-warning float-right">Pedido
                                                            no entregado</span>
                                                    </p>
                                                    <p v-if="pedidoFiltrado.entregado" class="text-white mb-0 font-16"><span
                                                            class="badge badge-success float-right">Pedido entregado</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card mb-0" v-for="articulo in pedidoFiltrado.articulos" :key="articulo">
                                            <div class="card-body font-18"><b>{{ articulo.cantidad }}</b> x <b>Rosco {{
                                                articulo.tamano }}, {{ articulo.relleno }}</b></div>
                                        </div>
                                        <div class="card shadow-none my-1 overflow-hidden">
                                            <div
                                                :class="`card-body ${pedidoFiltrado.pagado ? 'bg-gradient1' : 'bg-gradient3'}`">
                                                <div class="">
                                                    <div class="card-icon">
                                                        <i :class="`fa-duotone ${pedidoFiltrado.pagado ? 'fa-check' : 'fa-spinner'}`"></i>
                                                    </div>
                                                    <h4 class="font-weight-bold text-white">Total {{pedidoFiltrado.pagado ? 'pagado' : 'a pagar'}}: {{ calcularTotal() }}€</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 mt-3">
                                                <button @click="marcarPagado" v-if="!pedidoFiltrado.pagado" type="button"
                                                    class="btn btn-block text-white btn-warning waves-effect waves-light">Marcar
                                                    como pagado</button>
                                                <button @click="marcarPagado" v-if="pedidoFiltrado.pagado" type="button"
                                                    class="btn btn-block text-white btn-primary waves-effect waves-light">Marcar
                                                    como no pagado</button>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <button @click="marcarEntregado" v-if="!pedidoFiltrado.entregado"
                                                    type="button"
                                                    class="btn btn-block text-white btn-success waves-effect waves-light">Marcar
                                                    como entregado</button>
                                                <button @click="marcarEntregado" v-if="pedidoFiltrado.entregado"
                                                    type="button"
                                                    class="btn btn-block text-white btn-danger waves-effect waves-light">Marcar
                                                    como no entregado</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12" v-else>
                                <div class="card">
                                    <div class="card-body">
                                        <h2>Modificar pedido <button @click="modificar = false"
                                                v-if="!pedidoFiltrado.entregado" type="button"
                                                class="btn btn-danger waves-effect waves-light float-right"><i
                                                    class="fa-solid fa-pen-to-square"></i> Cancelar edición</button></h2>
                                        <div class="col-md-12 mb-3">
                                            <p><b>Día de entrega</b></p>
                                            <el-date-picker v-model="pedidoFiltrado.fecha" type="date" format="DD/MM/YYYY"
                                                value-format="YYYY-MM-DD" placeholder="Selecciona un día" size="large" />
                                        </div>
                                        <div class="card mb-0" v-for="articulo in pedidoFiltrado.articulos" :key="articulo">
                                            <div class="card-body font-18">
                                                <el-input-number size="large" v-model="articulo.cantidad" />
                                                <b><el-select v-model="articulo.tamano" class="m-2" placeholder="Tamaño"
                                                        size="large">
                                                        <el-option v-for="tamano in tamanos" :key="tamano"
                                                            :label="tamano.mostrar" :value="tamano.valor" />
                                                    </el-select>
                                                    <el-select v-model="articulo.relleno" class="m-2" placeholder="Tamaño"
                                                        size="large">
                                                        <el-option v-for="relleno in rellenos" :key="relleno"
                                                            :label="relleno.mostrar" :value="relleno.valor" />
                                                    </el-select></b>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 mt-3">
                                                <button @click="pedidoFiltrado.articulos.push({ cantidad: 1 })"
                                                    type="button"
                                                    class="btn btn-block text-white btn-warning waves-effect waves-light">Nuevo
                                                    artículo</button>
                                            </div>
                                            <div class="col-md-12 mt-3">
                                                <button type="button" @click="modificarPedido()"
                                                    class="btn btn-block text-white btn-success waves-effect waves-light">Guardar
                                                    cambios</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="idVer == 0">
                            <div class="col-lg-3">
                                <div class="card">
                                    <div class="card-body profile-nav">
                                        <div class="nav flex-column nav-pills" id="profile-tab" aria-orientation="vertical">
                                            <a :class="`nav-link mb-0 ${pagina == 'pendientes' ? 'active' : ''}`" id="resumen-dash-tab" data-toggle="pill"
                                                @click="pagina = 'pendientes'" aria-selected="true">Pedidos pendientes</a>
                                            <a :class="`nav-link mb-0 ${pagina == 'entregados' ? 'active' : ''}`" id="entregados-dash-tab" data-toggle="pill"
                                                @click="pagina = 'entregados'" aria-selected="true">Pedidos entregados</a>
                                            <a :class="`nav-link mb-0 ${pagina == 'nuevopedido' ? 'active' : ''}`" id="np-dash-tab" data-toggle="pill"
                                            @click="pagina = 'nuevopedido'" aria-selected="true">Nuevo pedido</a>
                                        </div>
                                    </div><!--end card-body-->
                                </div><!--end card-->
                            </div>
                            <div class="col-lg-9">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="tab-content" id="resumen-tabContent">
                                            <div v-if="pagina == 'pendientes'" class="tab-pane fade show active" id="resumen-dash">
                                                <h4 class="header-title mt-0">Pedidos pendientes de entrega</h4>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div v-if="pedidosPendientes.length == 0"
                                                            class="alert icon-custom-alert alert-outline-danger b-round fade show mb-0"
                                                            role="alert">
                                                            <i class="mdi mdi-alert-outline alert-icon"></i>
                                                            <div class="alert-text">
                                                                No se han encontrado pedidos pendientes para este usuario.
                                                            </div>
                                                        </div>
                                                        <div @click="idVer = pedido.id" v-for="pedido in pedidosPendientes"
                                                            :key="pedido" style="cursor:pointer;"
                                                            class="card shadow-none my-1 overflow-hidden">
                                                            <div class="card-body bg-gradient3">
                                                                <div class="">
                                                                    <div class="card-icon">
                                                                        <i class="fa-duotone fa-spinner"></i>
                                                                    </div>
                                                                    <h2 class="font-weight-bold text-white">{{
                                                                        pedido.fechaFormateada
                                                                    }}</h2>
                                                                    <p v-if="!pedido.pagado"
                                                                        class="text-white mb-0 font-16"><span
                                                                            class="badge badge-warning float-left">Pedido no
                                                                            pagado</span></p>
                                                                    <p v-if="pedido.pagado" class="text-white mb-0 font-16">
                                                                        <span class="badge badge-success float-left">Pedido
                                                                            pagado</span>
                                                                    </p>
                                                                    <p v-if="!pedido.entregado"
                                                                        class="text-white mb-0 font-16"><span
                                                                            class="badge badge-warning float-right">Pedido
                                                                            no entregado</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div><!--end col-->
                                                </div><!--end row-->
                                            </div><!--end tab-pane-->

                                            <div v-if="pagina == 'entregados'" class="tab-pane fade show active" id="entregados-dash">
                                                <h4 class="header-title mt-0">Pedidos entregados</h4>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div v-if="pedidosEntregados.length == 0"
                                                            class="alert icon-custom-alert alert-outline-danger b-round fade show mb-0"
                                                            role="alert">
                                                            <i class="mdi mdi-alert-outline alert-icon"></i>
                                                            <div class="alert-text">
                                                                No se han encontrado pedidos entregados para este usuario.
                                                            </div>
                                                        </div>
                                                        <div @click="idVer = pedido.id" v-for="pedido in pedidosEntregados"
                                                            :key="pedido" style="cursor:pointer;"
                                                            class="card shadow-none my-1 overflow-hidden">
                                                            <div class="card-body bg-gradient1">
                                                                <div class="">
                                                                    <div class="card-icon">
                                                                        <i class="fa-duotone fa-check"></i>
                                                                    </div>
                                                                    <h2 class="font-weight-bold text-white">{{
                                                                        pedido.fechaFormateada
                                                                    }}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div><!--end col-->
                                                </div><!--end row-->
                                            </div><!--end tab-pane-->

                                            <div v-if="pagina == 'nuevopedido'" class="tab-pane fade show active" id="np-dash">
                                                <h4 class="header-title mt-0">Nuevo pedido</h4>
                                                <div class="row">
                                                    <div class="col-md-12 mb-3">
                                                        <p><b>Día de entrega</b></p>
                                                        <el-date-picker v-model="nuevoPedido.fecha" type="date"
                                                            format="DD/MM/YYYY" value-format="YYYY-MM-DD"
                                                            placeholder="Selecciona un día" size="large" />
                                                    </div>
                                                    <div class="card mb-0" v-for="articulo in nuevoPedido.articulos"
                                                        :key="articulo">
                                                        <div class="card-body font-18">
                                                            <div class="row" style="align-items: center;">
                                                            <div class="col-md-4">
                                                                <el-input-number size="large" v-model="articulo.cantidad" />
                                                            </div>
                                                                <div class="col-md-4">
                                                                    <el-select v-model="articulo.tamano" class="m-2"
                                                                        placeholder="Tamaño" size="large">
                                                                        <el-option v-for="tamano in tamanos" :key="tamano"
                                                                            :label="tamano.mostrar" :value="tamano.valor" />
                                                                    </el-select>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <el-select v-model="articulo.relleno" class="m-2"
                                                                        placeholder="Relleno" size="large">
                                                                        <el-option v-for="relleno in rellenos"
                                                                            :key="relleno" :label="relleno.mostrar"
                                                                            :value="relleno.valor" />
                                                                    </el-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12 mt-3">
                                                            <button @click="nuevoPedido.articulos.push({ cantidad: 1 })"
                                                                type="button"
                                                                class="btn btn-block text-white btn-warning waves-effect waves-light">Nuevo
                                                                artículo</button>
                                                        </div>
                                                        <div class="col-md-12 mt-3">
                                                            <button type="button" @click="guardarPedido()"
                                                                class="btn btn-block text-white btn-success waves-effect waves-light"
                                                                :disabled="nuevoPedido.articulos.length == 0 || nuevoPedido.fecha == ''">Guardar
                                                                pedido</button>
                                                        </div>
                                                    </div>
                                                </div><!--end row-->
                                            </div><!--end tab-pane-->
                                        </div> <!--end tab-content-->
                                    </div><!--end card-body-->
                                </div><!--end card-->
                            </div><!--end col-->
                            <div class="col-lg-3">
                                <div class="card">
                                    <div class="card-body">
                                    <h4>Borrar cliente</h4>
                                    <el-switch v-model="confirmacionBorrar"/>
                                    <span :class="`ms-3 ${confirmacionBorrar ? 'text-danger font-weight-bold' : ''}`" @click="confirmacionBorrar = !confirmacionBorrar">Estoy seguro de que quiero eliminar a este cliente. Entiendo que este cambio es irreversible.</span>
                                    <button type="button" @click="borrarCliente()"
                                                                class="btn btn-block text-white btn-danger waves-effect waves-light mt-3"
                                                                :disabled="!confirmacionBorrar">Borrar</button>
                                </div>
                                </div><!--end card-->
                            </div>
                        </div><!--end row-->
                    </div><!-- container -->

                    <footer class="footer text-center text-sm-left">
                        &copy; 2019 Frogetor <span class="text-muted d-none d-sm-inline-block float-right">Crafted with <i
                                class="mdi mdi-heart text-danger"></i> by Mannatthemes</span>
                    </footer>
                </div>
                <!-- end page content -->
            </div>
            <!--end page-wrapper-inner -->
        </div>
        <!-- end page-wrapper -->
    </body>
</template>
  
<script>
import Header from './Header.vue'
import Sidenav from './Sidenav.vue'
import Swal from 'sweetalert2'
export default {
    name: 'PaginaCliente',
    props: {
        user: Object,
        sidenav: Boolean,
    },
    components: {
        Header,
        Sidenav
    },
    watch: {
        pedidoFiltrado: {
            handler: function () {
                if (this.pedidoFiltrado.length != 0 && this.pedidoFiltrado.articulos.length > 0) {
                    for (let i = this.pedidoFiltrado.articulos.length - 1; i >= 0; i--) {
                        const articulo = this.pedidoFiltrado.articulos[i];
                        if (articulo.cantidad === 0) {
                            this.pedidoFiltrado.articulos.splice(i, 1);
                        }
                    }
                }
            },
            deep: true
        },
        nuevoPedido: {
            handler: function () {
                if (this.nuevoPedido.length != 0 && this.nuevoPedido.articulos.length > 0) {
                    for (let i = this.nuevoPedido.articulos.length - 1; i >= 0; i--) {
                        const articulo = this.nuevoPedido.articulos[i];
                        if (articulo.cantidad === 0) {
                            this.nuevoPedido.articulos.splice(i, 1);
                        }
                    }
                }
            },
            deep: true
        },
        idVer() {
            setTimeout(() => {
                const elementoResumen = document.getElementById('resumen-dash-tab');
                if (elementoResumen) {
                    elementoResumen.click();
                } else {
                    console.log('El elemento no existe en el DOM');
                }
            }, 100);
        }
    },
    computed: {
        pedidosPendientes() {
            try {
                if (this.cliente && this.cliente.pedidos && Array.isArray(this.cliente.pedidos)) {
                    return this.cliente.pedidos.filter((pedido) => pedido.entregado === 0);
                } else {
                    console.warn('El cliente, sus pedidos o los pedidos no son un array.');
                }
            } catch (error) {
                console.error('Error al filtrar pedidos pendientes:', error);
            }
            return [];
        },
        pedidosEntregados() {
            try {
                if (this.cliente && this.cliente.pedidos && Array.isArray(this.cliente.pedidos)) {
                    return this.cliente.pedidos.filter((pedido) => pedido.entregado === 1);
                } else {
                    console.warn('El cliente, sus pedidos o los pedidos no son un array.');
                }
            } catch (error) {
                console.error('Error al filtrar pedidos pendientes:', error);
            }
            return [];
        },
        pedidoFiltrado() {
            if (this.idVer != 0) {
                return this.cliente.pedidos.find((pedido) => { return pedido.id == this.idVer })
            }
            return [];
        }
    },
    data() {
        return {
            usuario: '',
            loading: true,
            cliente: {},
            idVer: 0,
            modificar: false,
            tamanos: [],
            rellenos: [],
            nuevoPedido: {
                fecha: '',
                articulos: []
            },
            confirmacionBorrar: false,
            pagina: 'pendientes'
        }
    },
    mounted() {
        this.init();
        this.usuario = this.user
    },

    methods: {
        cambiarSidenav() {
           this.$emit('cambiarSidenav');
        },
        async init() {
            fetch(process.env.VUE_APP_BACKEND_URL + '/api/obtenerDatosRoscos')
                .then(response => response.json())
                .then(data => {
                    this.tamanos = data.tamanos;
                    this.rellenos = data.rellenos;
                    this.loading = false;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            const url = process.env.VUE_APP_BACKEND_URL + '/api/obtenerCliente';

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token, id: this.$route.params.id })
                });
                this.cliente = await response.json();
                this.loading = false;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        },
        async modificarPedido() {
            this.loading = true;
            const url = process.env.VUE_APP_BACKEND_URL + '/api/modificarPedido';

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token, pedido: this.pedidoFiltrado })
                });
                Swal.fire({
                    title: 'Modificación realizada correctamente',
                    text: 'El pedido ha sido modificado correctamente',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
                this.cliente = await response.json();
                this.modificar = false;
                this.loading = false;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        },
        async borrarCliente() {
            this.loading = true;
            const url = process.env.VUE_APP_BACKEND_URL + '/api/borrarCliente';

            try {
                await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token, cliente: this.cliente })
                });
                Swal.fire({
                    title: 'Modificación realizada',
                    text: 'El cliente ha sido eliminado correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
                this.$router.push('/clientes');
                this.loading = false;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        },
        async marcarPagado() {
            this.loading = true;
            const url = process.env.VUE_APP_BACKEND_URL + '/api/marcarPagado';

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token, pedido: this.pedidoFiltrado })
                });
                Swal.fire({
                    title: 'Modificación realizada correctamente',
                    text: 'El pedido ha sido modificado correctamente',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
                this.cliente = await response.json();
                this.modificar = false;
                this.loading = false;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        },
        async marcarEntregado() {
            this.loading = true;
            const url = process.env.VUE_APP_BACKEND_URL + '/api/marcarEntregado';

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token, pedido: this.pedidoFiltrado })
                });
                Swal.fire({
                    title: 'Modificación realizada correctamente',
                    text: 'El pedido ha sido modificado correctamente',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
                this.cliente = await response.json();
                this.modificar = false;
                this.loading = false;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        },
        async guardarPedido() {
            this.loading = true;
            const url = process.env.VUE_APP_BACKEND_URL + '/api/nuevoPedido';

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token, pedido: this.nuevoPedido, cliente: this.cliente })
                });
                Swal.fire({
                    title: 'Pedido creado correctamente',
                    text: 'El pedido ha sido creado correctamente',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
                this.cliente = await response.json();
                this.modificar = false;
                this.loading = false;
                this.nuevoPedido = {
                    fecha: '',
                    articulos: []
                }
                this.idVer = 0;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        },
        buscarTamano(valor) {
            return this.tamanos.find((tamano) => tamano.valor === valor);
        },
        buscarRelleno(valor) {
            return this.rellenos.find((relleno) => relleno.valor === valor);
        },
        calcularPrecio(rosco) {
            let respuesta = 0;
            let tamano = '';
            switch (rosco.relleno) {
                case 'normal':
                    tamano = this.buscarTamano(rosco.tamano);
                    respuesta = tamano.precio_normal;
                    break;
                default:
                    tamano = this.buscarTamano(rosco.tamano);
                    respuesta = tamano.precio_relleno;
                    break;
            }
            return respuesta;
        },
        calcularTotal() {
            let respuesta = 0;
            this.pedidoFiltrado.articulos.forEach((articulo) => {
                respuesta += this.calcularPrecio(articulo) * articulo.cantidad;
            })
            return respuesta;
        },
    }
}
</script>

<style lang="scss" scoped></style>
  