<template>
    <body>
        <Header :user="user" :onCambiarSidenav="cambiarSidenav" />
        <!-- Top Bar End -->
        <div class="page-wrapper">
            <div class="page-wrapper-inner">
                <!-- Left Sidenav -->
                <Sidenav :sidenav="sidenav" />
                <!-- end left-sidenav-->
                <!-- Page Content-->
                <div class="page-content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <el-select v-model="modo" class="w-100 mb-3" placeholder="Modo" size="large">
                                    <el-option label="Sumar" value="sumar" />
                                    <el-option label="Restar" value="restar" />
                                </el-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4" v-for="tamano in tamanos" :key="tamano">
                                <div class="card">
                                    <div class="card-body">
                                        <h3>{{ tamano.mostrar }}</h3>
                                    </div>
                                </div>
                                <div style="touch-action: manipulation;" :class="`btn btn-${calcularBoton(relleno.valor)} card`" v-for="relleno in rellenos"
                                    @click="sumar(tamano, relleno)">
                                    <div class="card-body">
                                        <h3 class="float-left">{{ relleno.mostrar }}</h3>
                                        <h3 class="float-right">{{ stock.length > 0 ? calcularStock(tamano,
                                            relleno).cantidad : '' }} <span
                                                v-if="calcularCambio(tamano, relleno) && calcularCambio(tamano, relleno).cantidad > 0"
                                                class="badge bg-success text-white">+ {{ calcularCambio(tamano, relleno) ?
                                                    calcularCambio(tamano, relleno).cantidad : '' }}</span>
                                            <span
                                                v-if="calcularCambio(tamano, relleno) && calcularCambio(tamano, relleno).cantidad < 0"
                                                class="badge bg-danger text-white">{{ calcularCambio(tamano, relleno) ?
                                                    calcularCambio(tamano, relleno).cantidad : '' }}</span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!-- container -->

                    <footer class="footer text-center text-sm-left">
                        &copy; 2019 Frogetor <span class="text-muted d-none d-sm-inline-block float-right">Crafted with <i
                                class="mdi mdi-heart text-danger"></i> by Mannatthemes</span>
                    </footer>
                </div>
                <!-- end page content -->
            </div>
            <!--end page-wrapper-inner -->
        </div>
        <!-- end page-wrapper -->
    </body>
</template>
  
<script>
import Header from './Header.vue'
import Sidenav from './Sidenav.vue'
import Swal from 'sweetalert2'
export default {
    name: 'PaginaAlmacen',
    props: {
        user: Object,
        sidenav: Boolean
    },
    components: {
        Header,
        Sidenav
    },
    computed: {
        filteredClientes() {
            let clientes = [].concat(this.clientes);
            let busqueda = this.busqueda.toLowerCase();
            if (busqueda) {
                clientes = clientes.filter((cliente) => { return cliente.name.toLowerCase().includes(busqueda) || (cliente.telefono && cliente.telefono.includes(busqueda)) })
            }
            return clientes;
        }
    },
    data() {
        return {
            configuracion: '',
            usuario: '',
            loading: true,
            tamanos: [],
            rellenos: [],
            stock: [],
            stocksuma: [],
            modo: 'sumar',
            temporizador: null,
            intervaloStock: null
        }
    },
    mounted() {
        this.init();
        this.usuario = this.user
        this.intervaloStock = setInterval(() => {
        this.obtenerStock();
    }, 10000);
    },
    beforeRouteLeave(to, from, next) {
    // Antes de cambiar de ruta, cancela el intervalo
    clearInterval(this.intervaloStock);
    next();
},
    methods: {
        cambiarSidenav() {
            this.$emit('cambiarSidenav');
        },
        async init() {
            fetch(process.env.VUE_APP_BACKEND_URL + '/api/obtenerDatosRoscos')
                .then(response => response.json())
                .then(data => {
                    this.tamanos = data.tamanos;
                    this.rellenos = data.rellenos;
                    this.loading = false;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            this.obtenerStock();
        },
        obtenerStock() {
            fetch(process.env.VUE_APP_BACKEND_URL + '/api/obtenerStock', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: this.user.token })
            })
                .then(response => response.json())
                .then(data => {
                    this.stock = data;
                    this.loading = false;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },
        calcularStock(tamano, relleno) {
            if (this.stock && Array.isArray(this.stock) && this.stock.length > 0) {
                return this.stock.find((stock) => { return stock.tamano == tamano.valor && stock.relleno == relleno.valor });
            }
            return { cantidad: 0 }
        },
        calcularCambio(tamano, relleno) {
            return this.stocksuma.find((stock) => { return stock.tamano == tamano.valor && stock.relleno == relleno.valor });
        },
        calcularBoton(valor) {
            switch (valor) {
                case 'normal':
                    return 'info';
                case 'nata':
                    return 'secondary';
                case 'crema':
                    return 'warning';
                default:
                    return 'primary';
            }
        },
        sumar(tamano, relleno) {
            let stocksuma = this.stocksuma.find((stock) => { return stock.tamano == tamano.valor && stock.relleno == relleno.valor })
            if (!stocksuma) {
                this.stocksuma.push({ tamano: tamano.valor, relleno: relleno.valor, cantidad: 0 })
            }
            stocksuma = this.stocksuma.find((stock) => { return stock.tamano == tamano.valor && stock.relleno == relleno.valor })
            if (this.modo == 'sumar') {
                stocksuma.cantidad++;
            } else {
                stocksuma.cantidad--;
            }
            this.manejarCambios();
        },
        manejarCambios() {
            // Reiniciar el temporizador
            clearTimeout(this.temporizador);
            this.temporizador = setTimeout(() => {
                fetch(process.env.VUE_APP_BACKEND_URL + '/api/actualizarStock', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token, cambios: this.stocksuma })
                })
                    .then(response => response.json())
                    .then(data => {
                        this.stock = data;
                        this.obtenerStock();
                        this.loading = false;
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        Swal.fire({
                            title: "Error actualizando los cambios",
                            text: "El stock no se ha actualizado",
                            icon: "error"
                        });
                        this.obtenerStock();
                    });
                this.stocksuma = [];
            }, 5000);
        },
    }
}
</script>

<style lang="scss" scoped>
#contenedorInicio {
    display: flex;
    justify-content: center;
    align-items: center;

    .texto {
        text-align: center;
        color: white;
        max-width: 75%;

        img {
            width: 30%;
        }

        h1 {
            margin-bottom: 5px;
        }

        h3 {
            margin-top: 0px;
            font-weight: normal;
        }

        button {
            font-size: 1.5rem;
            padding: 20px;

            i {
                margin-right: 10px;
            }
        }
    }
}
</style>
  