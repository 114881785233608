<template>
    <body>
        <Header :user="user" :onCambiarSidenav="cambiarSidenav" />
        <!-- Top Bar End -->
        <div class="page-wrapper">
            <div class="page-wrapper-inner">
                <!-- Left Sidenav -->
                <Sidenav :sidenav="sidenav" />
                <!-- end left-sidenav-->
                <!-- Page Content-->
                <div class="page-content">
                    <div class="container-fluid">
                        <div class="row" v-loading="loading">
                            <div class="card">
                                <div class="card-body">
                                    <h4>Listado de pendientes</h4>
                                    <div class="row" style="align-items:center;">
                                        <div class="col-md-7">
                                            <p><b>Fecha</b></p>
                                            <el-date-picker v-model="filtros.rangofechas" type="daterange"
                                                range-separator="hasta" start-placeholder="Fecha inicio"
                                                end-placeholder="Fecha fin" :size="large" value-format="YYYY-MM-DD"
                                                format="DD/MM/YYYY" />
                                        </div>
                                        <div class="col-md-5">
                                            <p><b>Modo</b></p>
                                            <el-select v-model="modo" class="m-2" placeholder="Select">
                                                <el-option label="Cantidad" value="cantidad" />
                                                <el-option label="Clientes" value="clientes" />
                                            </el-select>
                                        </div>
                                        <div class="col-md-12" v-if="modo == 'cantidad'">
                                            <div v-for="tamano in tamanos">
                                                <h3>{{ tamano.mostrar }} </h3>
                                                <p><b>En total: {{ aplicarFiltros[tamano.valor]['total'] }}</b></p>
                                                <p v-for="relleno in rellenos"><b>{{ relleno.mostrar }}: {{
                                                    aplicarFiltros[tamano.valor][relleno.valor] }}</b> <b class="text-success"
                                                        v-if="buscarStock(tamano, relleno) && buscarStock(tamano, relleno).cantidad > aplicarFiltros[tamano.valor][relleno.valor]">Sobran {{ buscarStock(tamano, relleno).cantidad - aplicarFiltros[tamano.valor][relleno.valor] }}</b>
                                                        <b class="text-danger"
                                                        v-if="buscarStock(tamano, relleno) && buscarStock(tamano, relleno).cantidad < aplicarFiltros[tamano.valor][relleno.valor]">Faltan {{ aplicarFiltros[tamano.valor][relleno.valor] - buscarStock(tamano, relleno).cantidad }}</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-md-12" v-if="modo == 'clientes'">
                                            <div v-for="tamano in tamanos">
                                                <h3>{{ tamano.mostrar }} </h3>
                                                <p><b>En total: {{ aplicarFiltros[tamano.valor].total }}</b></p>
                                                <div style="margin-top: 10px;" v-for="relleno in rellenos">
                                                    <b>{{ relleno.mostrar }}
                                                        ({{ aplicarFiltros[tamano.valor][relleno.valor] }}):</b>
                                                    <p style="margin: 0px;"
                                                        v-for="cliente in aplicarFiltros[tamano.valor].clientes[relleno.valor]">
                                                        {{ cliente.nombre }} ({{ cliente.cantidad }})</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div><!--end row-->
                    </div><!-- container -->

                    <footer class="footer text-center text-sm-left">
                        &copy; 2019 Frogetor <span class="text-muted d-none d-sm-inline-block float-right">Crafted with <i
                                class="mdi mdi-heart text-danger"></i> by Mannatthemes</span>
                    </footer>
                </div>
                <!-- end page content -->
            </div>
            <!--end page-wrapper-inner -->
        </div>
        <!-- end page-wrapper -->
    </body>
</template>
  
<script>
import Header from './Header.vue'
import Sidenav from './Sidenav.vue'
import moment from 'moment'
export default {
    name: 'ListadoPendientes',
    props: {
        user: Object,
        sidenav: Boolean
    },
    components: {
        Header,
        Sidenav
    },
    computed: {
        // Código base proporcionado por el usuario
        // No olvides rellenar esta parte con tu código original

        aplicarFiltros() {
            let salida = {};
            let salidar = {};
            let contador = {};
            let pendientes = [...this.pendientes]; // Copiamos el array para evitar modificar el original

            if (this.filtros.rangofechas.length > 0) {
                pendientes = pendientes.filter((pendiente) => pendiente.fecha_pedido >= this.filtros.rangofechas[0] && pendiente.fecha_pedido <= this.filtros.rangofechas[1]);
            }

            for (let i = 0; i < this.tamanos.length; i++) {
                contador[this.tamanos[i].valor] = { total: 0, clientes: { normal: {}, nata: {}, chocolate: {}, crema: {} } };
                salida[this.tamanos[i].valor] = pendientes.filter((art) => art.tamano === this.tamanos[i].valor);
                contador[this.tamanos[i].valor].total = salida[this.tamanos[i].valor].reduce((total, elemento) => total + elemento.cantidad, 0);

                for (let j = 0; j < this.rellenos.length; j++) {
                    salidar[this.rellenos[j].valor] = pendientes.filter((art) => art.relleno === this.rellenos[j].valor && art.tamano === this.tamanos[i].valor);
                    contador[this.tamanos[i].valor][this.rellenos[j].valor] = salidar[this.rellenos[j].valor].reduce((total, elemento) => total + elemento.cantidad, 0);

                    salidar[this.rellenos[j].valor].forEach((elemento) => {
                        const clienteKey = this.rellenos[j].valor;
                        const clienteCantidad = elemento.cantidad;
                        const clienteNombre = elemento.name;

                        // Verificar si ya existe el cliente en el objeto
                        if (!contador[this.tamanos[i].valor].clientes[clienteKey][clienteNombre]) {
                            contador[this.tamanos[i].valor].clientes[clienteKey][clienteNombre] = { nombre: clienteNombre, cantidad: 0 };
                        }

                        // Sumar la cantidad al cliente
                        contador[this.tamanos[i].valor].clientes[clienteKey][clienteNombre].cantidad += clienteCantidad;
                    });
                }
            }

            return contador;
        }



    },

    data() {
        return {
            usuario: '',
            loading: true,
            clientes: [],
            filtros: {
                rangofechas: [
                    moment().format('YYYY-MM-DD'),
                    moment().format('YYYY-MM-DD'),
                ],
                agrupacion: [],
            },
            tamanos: [],
            rellenos: [],
            pendientes: [],
            modo: 'cantidad',
            stock: []
        }
    },
    mounted() {
        this.init();
        this.usuario = this.user
    },

    methods: {
        cambiarSidenav() {
            this.$emit('cambiarSidenav');
        },
        buscarStock(tamano, relleno) {
            if (this.stock && Array.isArray(this.stock) && this.stock.length > 0) {
                return this.stock.find((stock) => { return stock.tamano == tamano.valor && stock.relleno == relleno.valor });
            }
            return { cantidad: 0 }
        },
        async init() {
            fetch(process.env.VUE_APP_BACKEND_URL + '/api/obtenerDatosRoscos')
                .then(response => response.json())
                .then(data => {
                    this.tamanos = data.tamanos;
                    this.rellenos = data.rellenos;
                    this.loading = false;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            const url = process.env.VUE_APP_BACKEND_URL + '/api/obtenerPendientes';

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token })
                });
                this.pendientes = await response.json();
                this.loading = false;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
            this.obtenerStock();
        },
        obtenerStock() {
            fetch(process.env.VUE_APP_BACKEND_URL + '/api/obtenerStock', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: this.user.token })
            })
                .then(response => response.json())
                .then(data => {
                    this.stock = data;
                    this.loading = false;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },
    }
}
</script>

<style lang="scss" scoped>
#contenedorInicio {
    display: flex;
    justify-content: center;
    align-items: center;

    .texto {
        text-align: center;
        color: white;
        max-width: 75%;

        img {
            width: 30%;
        }

        h1 {
            margin-bottom: 5px;
        }

        h3 {
            margin-top: 0px;
            font-weight: normal;
        }

        button {
            font-size: 1.5rem;
            padding: 20px;

            i {
                margin-right: 10px;
            }
        }
    }
}
</style>
  