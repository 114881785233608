<template>
    <div class="topbar">
            <!-- Navbar -->
            <nav class="navbar-custom">

                <!-- LOGO -->
                <div class="topbar-left">
                    <a href="index.html" class="logo">
                        <span>
                            <img src="@/assets/images/horno.png" alt="logo-small" class="logo-sm">
                        </span>
                        <span>
                            <img src="https://conservasartesanasme.es/img/conservas-artesanas-maria-eugenia-logo-1610022788.jpg" alt="logo-large" class="logo-lg">
                        </span>
                    </a>
                </div>

                

                <ul class="list-unstyled topbar-nav mb-0">

                    <li>
                        <button @click="cambiarSidenav()" class="button-menu-mobile nav-link waves-effect waves-light">
                            <i class="mdi mdi-menu nav-icon"></i>
                        </button>
                    </li>

                </ul>

            </nav>
            <!-- end navbar-->
        </div>
        <div class="page-wrapper-img">
            <div class="page-wrapper-img-inner">
                <div class="sidebar-user media">
                    <img :src="user.imagen ?? 'https://i.imgur.com/8n5W7Px.png'" alt="user" class="rounded-circle img-thumbnail mb-1">
                    <span class="online-icon"><i class="mdi mdi-record text-success"></i></span>
                    <div class="media-body">
                        <h5 class="text-light">{{user.name}}</h5>
                        <ul class="list-unstyled list-inline mb-0 mt-2">
                            <!-- <li class="list-inline-item">
                                <a href="#" class><i class="mdi mdi-account text-light"></i></a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#" class><i class="mdi mdi-settings text-light"></i></a>
                            </li> -->
                            <li @click="cerrarSesion" class="list-inline-item">
                                <a href="#" class><i class="mdi mdi-power text-danger"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Page-Title -->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <h4 class="page-title mb-2"><i class="mdi mdi-view-dashboard-outline mr-2"></i>{{nombreApartado}}</h4>
                        </div><!--end page title box-->
                    </div><!--end col-->
                </div><!--end row-->
                <!-- end page title end breadcrumb -->
            </div><!--end page-wrapper-img-inner-->
        </div><!--end page-wrapper-img-->
</template>
  
<script>
export default {
    name: 'NavHeader',
    props: {
        user: Object,
    },
    computed: {
        nombreApartado() {
            return this.$route.meta.title.split(' • ')[1];
        }
    },
    watch: {
        busqueda() {

        }
    },
    data() {
        return {
            busqueda: ''
        }
    },
    mounted() {
       
    },

    methods: {
        cambiarSidenav() {
            this.$emit('cambiarSidenav');
        },
        cerrarSesion() {
            localStorage.clear();
            window.location.reload();
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
  