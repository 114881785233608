<template>
    <body>
        <Header :user="user" :onCambiarSidenav="cambiarSidenav" />
        <!-- Top Bar End -->
        <div class="page-wrapper">
            <div class="page-wrapper-inner">
                <!-- Left Sidenav -->
                <Sidenav :sidenav="sidenav" />
                <!-- end left-sidenav-->
                <!-- Page Content-->
                <div class="page-content">
                    <div class="container-fluid">
                        <div class="row" v-loading="loading">
                            <div class="card">
                                <div class="card-body">
                                    <h4>Envío de avisos</h4>
                                    <div class="row" style="align-items:center;">
                                        <div class="col-md-7 mb-3">
                                            <p><b>Fecha</b></p>
                                            <el-date-picker v-model="filtros.rangofechas" type="daterange"
                                                range-separator="hasta" start-placeholder="Fecha inicio"
                                                end-placeholder="Fecha fin" :size="large" value-format="YYYY-MM-DD"
                                                format="DD/MM/YYYY" />
                                        </div>
                                        <div class="col-md-5 mb-3">
                                            <p><b>Saldo</b></p>
                                            <b>{{ saldo }}</b> mensajes restantes
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <p><b>Mensaje</b></p>
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <textarea v-model="mensaje" maxlength="160"
                                                        class="form-control"></textarea>
                                                </div>
                                                <div class="col-md-2">
                                                    <button @click="enviar" class="btn btn-block btn-primary"><i
                                                            class="fa-duotone fa-paper-plane"></i> Enviar</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div v-if="aplicarFiltros.size == 0"
                                                class="alert icon-custom-alert alert-outline-pink b-round fade show mb-0"
                                                role="alert">
                                                <i class="mdi mdi-alert-outline alert-icon"></i>
                                                <div class="alert-text">
                                                    <strong>Oops</strong> No hay entregas pendientes para el rango de fechas
                                                    seleccionado.
                                                </div>
                                                <div class="alert-close">
                                                    <button type="button" class="close" data-dismiss="alert"
                                                        aria-label="Close">
                                                        <span aria-hidden="true"><i
                                                                class="mdi mdi-close text-danger"></i></span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div v-for="cliente in filtrado" style="cursor:pointer"
                                                @click="cliente.telefono ? cliente.seleccionado = !cliente.seleccionado : ''">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <span
                                                            :class="cliente.seleccionado ? 'text-success' : 'text-danger'">{{
                                                                cliente.nombre }} <span v-if="cliente.telefono">({{
        cliente.telefono }})
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div><!--end row-->
                    </div><!-- container -->

                    <footer class="footer text-center text-sm-left">
                        &copy; 2019 Frogetor <span class="text-muted d-none d-sm-inline-block float-right">Crafted with <i
                                class="mdi mdi-heart text-danger"></i> by Mannatthemes</span>
                    </footer>
                </div>
                <!-- end page content -->
            </div>
            <!--end page-wrapper-inner -->
        </div>
        <!-- end page-wrapper -->
    </body>
</template>
  
<script>
import Header from './Header.vue'
import Sidenav from './Sidenav.vue'
import moment from 'moment'
import Swal from 'sweetalert2'
export default {
    name: 'EnviarAvisos',
    props: {
        user: Object,
        sidenav: Boolean
    },
    components: {
        Header,
        Sidenav
    },
    computed: {
        // Código base proporcionado por el usuario
        // No olvides rellenar esta parte con tu código original
        aplicarFiltros() {
            let pendientes = [...this.pendientes]; // Copiamos el array para evitar modificar el original
            if (this.filtros.rangofechas.length > 0) {
                pendientes = pendientes.filter((pendiente) => pendiente.fecha_pedido >= this.filtros.rangofechas[0] && pendiente.fecha_pedido <= this.filtros.rangofechas[1]);
            }
            let salida = new Set();
            pendientes.forEach((pendiente) => {
                salida.add({ nombre: pendiente.name, telefono: pendiente.telefono && pendiente.telefono.length == 9 && (pendiente.telefono.charAt(0) == '6' || pendiente.telefono.charAt(0) == '7') ? pendiente.telefono : undefined, seleccionado: pendiente.telefono ? true : false })
            })
            this.filtrado = salida;
            return salida;
        },
        telefonosFiltrados() {
            let salida = new Set();
            this.filtrado.forEach((cliente) => {
                if (cliente.seleccionado && cliente.telefono && cliente.telefono.length == 9 && (cliente.telefono.charAt(0) == '6' || cliente.telefono.charAt(0) == '7')) {
                    salida.add(cliente.telefono)
                }
            })
            return salida;
        }
    },

    data() {
        return {
            usuario: '',
            loading: true,
            clientes: [],
            filtros: {
                rangofechas: [
                    moment().format('YYYY-MM-DD'),
                    moment().format('YYYY-MM-DD'),
                ],
                agrupacion: [],
            },
            tamanos: [],
            rellenos: [],
            pendientes: [],
            modo: 'cantidad',
            filtrado: [],
            mensaje: '',
            saldo: 0
        }
    },
    mounted() {
        this.init();
        this.usuario = this.user
    },

    methods: {
        cambiarSidenav() {
            this.$emit('cambiarSidenav');
        },
        async init() {
            fetch(process.env.VUE_APP_BACKEND_URL + '/api/obtenerDatosRoscos')
                .then(response => response.json())
                .then(data => {
                    this.tamanos = data.tamanos;
                    this.rellenos = data.rellenos;
                    this.loading = false;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            const url = process.env.VUE_APP_BACKEND_URL + '/api/obtenerPendientes';

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token })
                });
                this.pendientes = await response.json();
                this.loading = false;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        },
        async enviar() {
            this.loading = true;
            let ids = [];
            for (let i = 0; i < this.telefonosFiltrados.size; i++) {
                ids.push('' + Date.now() + '' + i);
            }
            let telefonos = [...this.telefonosFiltrados].join(',');
            console.log(ids);
            console.log(telefonos);
            try {
                const response = await fetch('https://api.smsarena.es/http/sms.php?auth_key=c8kZBa56JzX4Mdxg3WVuO5MCgbX9E92x&id=' + ids + '&from=Goimar&to=' + telefonos + '&text=' + this.mensaje, {
                    mode: 'no-cors'
                });
                this.loading = false;
                Swal.fire({
                    title: "¡Bien!",
                    text: "Mensaje enviado correctamente",
                    icon: "success"
                });
            } catch (error) {
                Swal.fire({
                    title: "Oops",
                    text: "Ha ocurrido un error enviando el mensaje",
                    icon: "error"
                });
                console.error('Error en la solicitud:', error.message);
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#contenedorInicio {
    display: flex;
    justify-content: center;
    align-items: center;

    .texto {
        text-align: center;
        color: white;
        max-width: 75%;

        img {
            width: 30%;
        }

        h1 {
            margin-bottom: 5px;
        }

        h3 {
            margin-top: 0px;
            font-weight: normal;
        }

        button {
            font-size: 1.5rem;
            padding: 20px;

            i {
                margin-right: 10px;
            }
        }
    }
}
</style>
  