<template>
    <body class="account-body">

        <!-- Log In page -->
        <div class="row vh-100">
            <div class="col-lg-3  pr-0">
                <div class="card mb-0 shadow-none">
                    <div class="card-body">

                        <div class="px-3">
                            <div class="media">
                                <a href="index.html" class="logo logo-admin"><img src="@/assets/images/horno.png"
                                        height="55" alt="logo" class="my-3"></a>
                                <div class="media-body ml-3 align-self-center">
                                    <h4 class="mt-0 mb-1">Iniciar sesión</h4>
                                    <p class="text-muted mb-0">Inicia sesión para continuar en Horno Goimar</p>
                                </div>
                            </div>
                            <div class="form-horizontal my-4" method="POST">
                                <div class="form-group">
                                    <label for="username">Nombre de usuario</label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i
                                                    class="mdi mdi-account-outline font-16"></i></span>
                                        </div>
                                        <input v-model="usuario" type="text" class="form-control" id="username" name="user"
                                            placeholder="Introduce nombre de usuario" autocomplete="username">
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="userpassword">Contraseña</label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon2"><i
                                                    class="mdi mdi-key font-16"></i></span>
                                        </div>
                                        <input v-model="password" type="password" class="form-control" id="userpassword"
                                            name="password" placeholder="Introduce contraseña"
                                            autocomplete="current-password">
                                    </div>
                                </div>
                                <div class="form-group mb-0 row">
                                    <div class="col-12 mt-2">
                                        <button @click="submit" class="btn btn-primary btn-block waves-effect waves-light"
                                            name="login">Iniciar sesión
                                            <i class="fas fa-sign-in-alt ml-1"></i></button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 p-0 d-flex justify-content-center">
                <div class="accountbg d-flex align-items-center">
                    <div class="account-title text-white text-center">
                        <img src="@/assets/images/horno.png" alt="" class="thumb-sm">
                        <h4 class="mt-3">Panel de administración</h4>
                        <div class="border w-25 mx-auto border-primary"></div>
                        <h1 class="">Horno<b>Goimar</b></h1>
                        <!-- <p class="font-14 mt-3">Don't have an account ? <a href="" class="text-primary">Sign up</a></p> -->

                    </div>
                </div>
            </div>
        </div>
    </body>
</template>
  
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
export default {
    name: 'PaginaLogin',
    props: {
        user: Object,
    },
    data() {
        return {
            usuario: '',
            password: '',
        }
    },
    mounted() {
        this.init()
    },

    methods: {
        async init() {
            console.log('hola')
        },
        async submit() {
            const apiUrl = process.env.VUE_APP_BACKEND_URL + '/api/login';
            const postData = {
                username: this.usuario,
                password: this.password
            };
            axios.post(apiUrl, postData)
                .then(async response => {
                    if (response.status == 200) {
                        let usuario = response.data;
                        this.$emit('actualizarUser', usuario);
                        localStorage.setItem('usuario', JSON.stringify(usuario));
                        Swal.fire({
                            title: 'Inicio de sesión correcto',
                            text: '¡Bienvenid@, ' + usuario.user + '!',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        })
                        this.$router.push('/');
                    } else {
                        throw new Error('Error en la solicitud: ' + response.status);
                    }
                })
                .catch(error => {
                    console.error('Error al realizar la solicitud POST:', error);
                    Swal.fire({
                        title: 'Error iniciando sesión',
                        text: 'Por favor, verifica tus datos.',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                });
        }
    }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/bootstrap.min.css');
@import url('@/assets/css/metismenu.min.css');
@import url('@/assets/css/icons.css');
@import url('@/assets/css/style.css');
@import url('@/assets/awesome/css/all.css');

#contenedorInicio {
    display: flex;
    justify-content: center;
    align-items: center;

    .texto {
        text-align: center;
        color: white;
        max-width: 75%;

        img {
            width: 30%;
        }

        h1 {
            margin-bottom: 5px;
        }

        h3 {
            margin-top: 0px;
            font-weight: normal;
        }

        button {
            font-size: 1.5rem;
            padding: 20px;

            i {
                margin-right: 10px;
            }
        }
    }
}</style>
  